.nft-marketplace-section {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    h2 {
        color: #002a95;
        font-size: 38px;
        letter-spacing: 2px;
        margin-top: 80px;
        text-align: center;
        margin-bottom: 60px;
        font-weight: 800;
    }
    h4 {
        color: #002a95;
        font-size: 25px;
        letter-spacing: 2px;
        font-weight: 800;
        text-align: center;
    }
    .description {
        color: #544f4f;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 1px;
        text-align: justify;
        margin-bottom: 60px;
    }
    .nft-box {
        h4 {
            color: #002a95;
            font-size: 22px;
            letter-spacing: 2px;
            font-weight: 800;
            text-align: start;
        }
        .content {
            color: #544f4f;
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 1px;
            margin-top: 25px;
            margin-bottom: 15px;
        }
        .child-content {
            color: #544f4f;
            font-size: 20px;
        }
    }
    .img-parent {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .multiple-chains-sec {
        margin-top: 100px;
    }
    .multiple-chains {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
        min-height: 150px;
        padding: 10px 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        flex-direction: column;
        margin-top: 50px;
        .img-parent {
            width: 100%;
            height: 200px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .about-chains {
            margin-top: 20px;
            .name {
                font-size: 20px;
                color: #544f4f;
                font-weight: 700;
            }
            .about {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 400;
                color: #544f4f;
                text-align: start;
            }
        }
    }
    .expertise {
        margin-top: 80px;
        ol {
            margin-top: 50px;
            li {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 20px;
                span {
                    font-size: 22px;
                    color: black;
                    font-weight: 800;
                }
            }
        }
    }
    .bg-gray {
        background: #f2f2f2;
        padding: 30px 30px;
        margin-top: 80px;
        .feature-nft {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 150px;
            padding: 10px 22px;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            text-align: center;
            flex-direction: column;
            .name {
                font-size: 20px;
                color: black;
                font-weight: 500;
                margin-bottom: 10px;
                height: 52px;
                text-align: center;
                display: flex;
                align-items: center;
            }
            .about {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
            }
        }
    }
    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;
        h4 {
            text-align: start;
        }
        ul {
            margin-top: 10px;
            padding-left: 10px;
            li {
                font-size: 16px;
                letter-spacing: 0.5px;
            }
        }
        .image-parent {
            // width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
