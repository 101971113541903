// ++++++++++++++++++++++
// Variables
// ++++++++++++++++++++++
@import "../../../App.scss";

$primary-color: #5764ec;
$text-gray: #919289;

.navbar-area {
  background-color: #ffffff;
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100px;

  .inspire-nav {
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    // box-shadow: 5px 7px 15px 2px rgba(82, 90, 101,  0.12);
    .inspire-fixed-nav {
      position: relative;
    }
    .navbar-light {
      .mean-menu {
        justify-content: flex-end;
        // margin: unset;
      }
      .navbar-brand {
        width: 105px;
        height: 100px;
        .logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .navbar-nav {
        margin-right: 15px;
        .nav-item {
          position: relative;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 40px;
          &:first-child {
            padding-left: 0px;
          }

          a {
            font-size: 15.3px;
            font-weight: 400;
            color: #333;
            text-transform: capitalize;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin-left: 15px;
            margin-right: 15px;
            text-decoration: unset;
            &:hover {
              color: $primary-color-red;
            }
            &:first-child {
              margin-left: 0px !important;
            }
          }
          .active {
            color: $primary-color-red;
          }
          .dropdown-btn {
            text-align: left;
          }
          .mobile-dropdown-menu {
            list-style: none;
            padding: 0px 8px;
            text-align: left;
        }
        }
        .dropdown {
          position: unset;
          .dropdown-btn {
            cursor: pointer;
            text-align: left;
          }
          
          .dropdown-content-parent {
            display: none;
            &.active {
              position: fixed;
              z-index: 0;
              padding-top: 100px;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              background-color: rgb(0, 0, 0);
              background-color: rgba(0, 0, 0, 0.1);
            }
            &.tech-active {
              display: block;
              position: fixed;
              z-index: 0;
              padding-top: 100px;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              background-color: rgb(0, 0, 0);
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            width: calc(100% - 100px);
            // height: 465px;
            z-index: 1;
            // left: 0;
            right: 0;
            top: 93px;
            &.tech-active {
              width: unset;
              height: unset;
              left: unset;
              right: unset;
              display: block;
              padding: 25px;
            }
            .menu {
              list-style: none;
              padding: 0px;
              li {
                padding-bottom: 10px;
              }
            }
            .row {
              height: 100%;
              .mega-menu {
                height: 100%;
                .header {
                  font-size: 20px;
                  color: #1177d9;
                }
                .content {
                  ul {
                    padding: 0px;
                    list-style: none;
                  }
                }
              }
            }
          }
          .active {
            display: block;
          }
        }
      }
      .mobile-sidebar {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background-color: #fff;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        padding: 20px;
        z-index: 999;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;

        &.show {
          display: block;
          transform: translateX(0);
        }
        .toggle-cancel-icon {
          border: 1px solid #e5e5e5;
          background: transparent;
          padding: 5px 10px;
          border-radius: 8px;
          margin-bottom: 15px;
        }
        .navbar-nav {
          flex-direction: column;

          .nav-item {
            padding: 10px 0;
            text-align: center;

            a {
              color: #333;
              text-decoration: none;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar-area {
    .inspire-nav {
      .navbar-light {
        .navbar-nav {
          .dropdown {
            .dropdown-btn {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar-area {
    .inspire-nav {
      .navbar-light {
        .mobile-sidebar {
          .navbar-nav {
            display: flex;
            align-items: flex-start;
          }
        }
        .navbar-nav {
          display: none;
        }
      }
    }
  }
}
