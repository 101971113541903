.digital-marketing-section {
    .banner {
        width: 100%;
        height: 500px;

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    .img-parent {
        // width: 100%;
        height: auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    h2 {
        color: #0d0a7d;
        font-size: 38px;
        letter-spacing: 2px;
        margin-top: 80px;
        text-align: center;
        margin-bottom: 60px;
        font-weight: 800;
    }

    h4 {
        color: #0d0a7d;
        font-size: 25px;
        letter-spacing: 2px;
        font-weight: 800;
        text-align: center;
    }

    .description {
        color: #544f4f;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 1px;
        text-align: justify;
        margin-bottom: 60px;
    }

    .feature-title {
        margin-top: 80px;
    }

    .feature-box {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
        min-height: 150px;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        h6 {
            color: #0d0a7d;
            margin-top: 15px;
            font-size: 20px;
            font-weight: 700;
        }

        .content {
            font-size: 16px;
            font-weight: 400;
            color: #544f4f;
            text-align: center;
            margin-top: 10px;
        }
    }

    .services {
        h6 {
            color: #0d0a7d;
            font-size: 20px;
            font-weight: 800;
        }

        .content {
            color: #544f4f;
            font-size: 18px;
            margin-bottom: 30px;
        }

        ul {
            padding-left: 12px;

            li {
                margin-bottom: 10px;

                span {
                    font-size: 21px;
                    font-weight: 700;
                    color: black;
                }

                color: #544f4f;
                font-weight: 400;
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 1px;
            }
        }
    }

    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;

        h4 {
            text-align: start;
        }

        .content {
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 0.7px;
        }
        .image-parent {
            // width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}