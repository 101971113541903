.portfolio-section {
  .banner {
    width: 100%;
    height: 90vh;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .top_content_wrap {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    color: white;
    .sm-title {
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .heading1 {
      font-size: 5.8vw;
      font-weight: 900;
    }
    .app__subhead {
      font-size: 20px;
      line-height: 30px;
      margin-top: 10px;
    }
  }
  h2 {
    color: #0d0a7d;
    font-size: 38px;
    letter-spacing: 2px;
    margin-top: 60px;
    text-align: center;
    margin-bottom: 60px;
    font-weight: 800;
  }
  .tabs-parent {
    width: 100%;
    height: auto;
  }
  .bloc-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tabs {
    padding: 5px 2px;
    text-align: start;
    width: auto;
    background: white;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-right: 20px;
    letter-spacing: 1.25px;
  }
  .active-tabs {
    color: #e95832;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    border-bottom: 1px solid #e95832;
  }
  .content-tabs {
    flex-grow: 1;
    width: 100%;
    .size-selection {
      display: flex;
      justify-content: start;
      column-gap: 10px;
      flex-wrap: wrap;
      row-gap: 10px;
      @media screen and (max-width: 575px) {
        height: 100px;
        overflow-y: auto;
      }
      .sizes {
        width: 40%;
        background: #ffffff;
        border: 1px solid rgba(239, 239, 239, 0.6);
        border-radius: 4px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1090px) {
          width: 35%;
        }
        @media screen and (max-width: 920px) {
          width: 40%;
        }
        @media screen and (max-width: 575px) {
          width: 35%;
        }
        @media screen and (max-width: 400px) {
          width: 100%;
          padding: 10px;
        }
        label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          .input {
            accent-color: red;
          }
        }
      }
    }
  }
  .content {
    background: white;
    padding: 20px 0px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .active-content {
    display: block;
  }
  .porfolios-parent {
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
    @mixin project-card-parent($bg-color, $border-color) {
      border-radius: 20px;
      background-color: $bg-color;
      padding: 25px 24px;
      position: relative;
      height: auto;
      .img-parent {
        position: absolute;
        left: 1px;
        height: calc(100% - 2px);
        top: 1px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
      .project-description-parent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 40px;
        .project-heading {
          font-size: 53px;
          font-weight: 900;
          border-bottom: 1px solid $border-color;
          color: black;
          letter-spacing: 3px;
          line-height: 62px;
        }
        .project-description {
          font-size: 18px;
          line-height: 30px;
          color: #6b5d5d;
          letter-spacing: 0px;
        }
        .read-btn {
          background-color: $border-color;
          border-color: $border-color;
          font-size: 12px;
          font-weight: 400;
          padding: 10px 25px 10px 25px;
          color: white;
          svg {
            padding-left: 12px;
          }
        }
      }
    }
    .fit-parent {
      @include project-card-parent($bg-color: #f9e8f1, $border-color: #e24f78);
    }
    .even-parent {
      @include project-card-parent($bg-color: #b8c6d3, $border-color: #2e8ff0);
      .img-parent {
        position: absolute;
        left: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 1px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .phiz-parent {
      @include project-card-parent($bg-color: #f3c19d, $border-color: #e66905);
    }
    .namaste-parent {
      @include project-card-parent($bg-color: #becbdf, $border-color: #399af1);
    }
    .slash-parent {
      @include project-card-parent($bg-color: #efa443, $border-color: #e34634);
      .img-parent {
        position: absolute;
        left: unset;
        width: 45%;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 1px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .shareching-parent {
      @include project-card-parent($bg-color: #3c3c3c, $border-color: black);
      .project-description-parent {
        .project-heading {
          color: white;
        }
        .project-description {
          color: white;
        }
      }
    }
    .global-parent {
      @include project-card-parent($bg-color: #bcd7f9, $border-color: #090147);
      .project-description-parent {
        .project-heading {
          color: black;
        }
        .project-description {
          color: black;
        }
      }
    }
    .nft-exchange-parent {
      @include project-card-parent($bg-color: #9b98cc, $border-color: #7366f0);
      .project-description-parent {
        .project-heading {
          color: black;
        }
        .project-description {
          color: black;
        }
      }
    }
    .wallet-parent {
      @include project-card-parent($bg-color: #e5a524, $border-color: black);
      .img-parent {
        position: absolute;
        left: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 1px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .steave-parent {
      @include project-card-parent($bg-color: #292929, $border-color: white);
      .img-parent {
        width: 42%;
      }
      .project-description-parent {
        .project-heading {
          color: white;
        }
        .project-description {
          color: white;
        }
        .read-btn {
          background-color: transparent;
        }
      }
    }
    .udey-parent {
      @include project-card-parent($bg-color: #b8c6d3, $border-color: #2e8ff0);
      .img-parent {
        position: absolute;
        left: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 45px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        width: 265px;
        height: 200px;
        top: 20%;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .kinder-parent {
      @include project-card-parent(
        $bg-color: rgb(167, 166, 166),
        $border-color: white
      );
      .img-parent {
        width: 38%;
        height: 145px;
        top: 32%;
      }
      .project-description-parent {
        .project-heading {
          color: white;
        }
        .project-description {
          color: white;
        }
        .read-btn {
          background-color: transparent;
        }
      }
    }
    .pet-parent {
      @include project-card-parent($bg-color: #d7bda9, $border-color: #d76a1b);
      .img-parent {
        position: absolute;
        left: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 8px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        width: 425px;
        height: 172px;
        top: 20%;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .sports-parent {
      @include project-card-parent($bg-color: #7c712b, $border-color: white);
      .img-parent {
        width: 38%;
        height: 145px;
        top: 32%;
      }
      .project-description-parent {
        .project-heading {
          color: white;
        }
        .project-description {
          color: white;
        }
        .read-btn {
          background-color: transparent;
        }
      }
    }
    .forever-rose-parent {
      @include project-card-parent($bg-color: #d7bda9, $border-color: #c83b19);
      .img-parent {
        position: absolute;
        left: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 48px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        width: 352px;
        height: 320px;
        top: 5%;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .kos-parent {
      @include project-card-parent($bg-color: #76766e, $border-color: white);
      .img-parent {
        width: 38%;
        height: 100%;
      }
      .project-description-parent {
        .project-heading {
          color: white;
        }
        .project-description {
          color: white;
        }
        .read-btn {
          background-color: transparent;
        }
      }
    }
    .kenz-parent {
      @include project-card-parent($bg-color: #b0bb9f, $border-color: #2d620b);
      .img-parent {
        position: absolute;
        left: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        right: 48px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        width: 300px;
        height: 180px;
        top: 17%;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }

  .feature-box {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
    min-height: 150px;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    @media screen and (min-width: 768px) {
      width: 23%;
    }
    img {
      width: 100%;
      height: 241px;
    }
    h6 {
      color: #0d0a7d;
      margin-top: 25px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.case-study-parent {
  .case-studies-hero-section {
    border-bottom: 1px solid #eee;
    .img-parent {
      width: 100%;
      height: 400px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .share-img-parent {
      width: 100%;
      height: 450px;
      // position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        right: -100px;
      }
      &:before {
        position: absolute;
        background-image: url(../../images/shareching-login.png);
        content: "";
        display: block;
        background-size: 222px 388px;
        width: 252px;
        height: 450px;
        top: 187px;
        right: 22%;
        box-sizing: inherit;
        z-index: 0;
        background-repeat: no-repeat;
        background-position: top 0 left 0;
      }
    }
    .project-description-parent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 40px;
      .project-heading {
        font-size: 53px;
        font-weight: 900;
        border-bottom: 1px solid white;
        color: black;
        letter-spacing: 3px;
        line-height: 62px;
      }
      .project-description {
        font-size: 20px;
        line-height: 30px;
        color: #6b5d5d;
        letter-spacing: 1px;
      }
      .read-btn {
        background-color: black;
        border-color: white;
        font-size: 12px;
        font-weight: 400;
        padding: 10px 25px 10px 25px;
        color: white;
        svg {
          padding-left: 12px;
        }
      }
    }
  }
  .shareching-about-img-parent {
    width: 100%;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
      left: -15%;
    }
  }
  // .shareching-about-head,
  // .shareching-case-description {
  //   text-align: end;
  // }
  .project-obj-parent {
    padding: 50px 0px;
    background: #6969693b;
    .port_obect {
      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 800;
      }
      .case-description {
        text-align: center;
      }
    }
  }
  .concept-parent {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    .concept-img-parent {
      width: 100%;
      height: 400px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
      }
    }
  }
  .shareching-tech_use {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.1);
    min-height: 150px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    .img-parent {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .bg-gray {
    background: #f2f2f2;
    padding: 30px 30px;
    margin-top: 80px;
    p {
      color: gray;
      font-size: 18px;
      margin-top: 40px;
      line-height: 30px;
      letter-spacing: 1px;
    }
    .timeline-center {
      position: relative;
      &:before {
        background: red;
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        height: 88%;
        width: 2px;
      }
      .timeline-row {
        .tm-icon {
          width: 60px;
          height: 60px;
          text-align: center;
          position: absolute;
          top: 0;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 10px 0 rgb(138 138 138 / 16%);
          span {
            color: red;
            font-size: 30px;
            line-height: 60px;
            padding: 1px;
          }
        }
        .tm-details {
          h4 {
            color: #000000;
            font-weight: 700;
          }
        }

        &:nth-of-type(odd) {
          position: relative;
          text-align: right;
          width: 50%;
          .tm-icon {
            right: -30px;
          }
          .tm-details {
            margin-right: 40px;
            margin-bottom: 40px;
            color: #5f5f5f;
            font-size: 16px;
            line-height: 20px;
            background: #fff;
            padding: 20px;
          }
        }
        &:nth-of-type(even) {
          position: relative;
          text-align: left;
          width: 50%;
          margin-left: 50%;
          .tm-icon {
            left: -30px;
          }
          .tm-details {
            margin-left: 40px;
            margin-bottom: 0px;
            color: #5f5f5f;
            font-size: 16px;
            line-height: 20px;
            background: #fff;
            padding: 20px;
          }
        }
      }
    }
  }
  .challenge-img-parent {
    width: 100%;
    height: 400px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:before {
      position: absolute;
      background: url(../../images/shareching-project-rotate.png);
      content: "";
      display: block;
      background-size: 387px 193px;
      width: 474px;
      height: 292px;
      right: -60%;
      box-sizing: inherit;
      z-index: 0;
      top: 62%;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
    }
  }
  .solution-img-parent {
    width: 100%;
    height: 400px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
    }
    &:before {
      position: absolute;
      background: url(../../images/shareching-solution-rotate.png);
      content: "";
      display: block;
      background-size: 387px 193px;
      width: 474px;
      height: 292px;
      right: -2%;
      box-sizing: inherit;
      z-index: 0;
      top: 48%;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
    }
  }
  // .solution-heading {
  //   text-align: right;
  // }
  // .shareching-points-description {
  //   text-align: right;
  // }
  h2,
  h3 {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 42px;
  }
  .case-description {
    font-size: 18px;
    color: black;
    line-height: 35px;
    letter-spacing: 1px;
  }
  .concept,
  .tech_use,
  .show_features,
  .functions {
    padding-bottom: 30px;
    .bullet_list {
      list-style-type: disc;
      padding-left: 15px;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 500;
      padding-top: 20px;
      li {
        font-weight: 400;
        color: black;
      }
    }
  }
  .project-points {
    .points-description {
      font-size: 18px;
      color: black;
      line-height: 35px;
      letter-spacing: 1px;
    }
  }
}
