.section-dapp{
    .banner {
      width: 100%;
      height: 500px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    h2 {
      margin-top: 100px;
      text-align: center;
      margin-bottom: 50px;
      font-weight: 800;
    }
    .description {
      color: gray;
      font-size: 18px;
      text-align: justify;
      margin-bottom: 80px;
      line-height: 35px;
      letter-spacing: 1px;
    }
    ul {
        color: gray;
        font-size: 18px;
        text-align: justify;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .bg-gray {
        background: #f2f2f2;
        padding: 50px 30px;
        .feature-box {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 150px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            h6 {
                font-size: 20px;
                font-weight: 700;
            }
            .content {
                font-size: 16px;
                font-weight: 400;
                color: gray;
                text-align: center;
                margin-top: 10px;
                line-height: 25px;
                letter-spacing: 0px;
            }
        }
    }
    .img-parent {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
}