.stable-coin-section,
.token-section {
  .banner {
    width: 100%;
    height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  h2 {
    color: #1f30b4;
    font-size: 38px;
    letter-spacing: 2px;
    margin-top: 80px;
    text-align: center;
    margin-bottom: 60px;
    font-weight: 800;
  }

  .description {
    color: gray;
    font-size: 18px;
    text-align: justify;
    margin-bottom: 80px;
    line-height: 35px;
    letter-spacing: 1px;
  }

  h4 {
    color: #1f30b4;
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: 800;
  }

  .feature {
    margin-top: 80px;
    width: 100%;
    padding: 80px 0px;
    min-height: 500px;
    background: url(../../images/stable-coin-feature.jpg);

    h4 {
      font-size: 28px;
      font-weight: 800;
      padding-bottom: 60px;
      letter-spacing: 2px;
      color: white;
      text-align: center;
    }

    .feature-box {
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
      min-height: 150px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      flex-direction: column;

      h6 {
        font-size: 22px;
      }

      .content {
        font-size: 18px;
        font-weight: 400;
        color: #544f4f;
        text-align: justify;
        margin-top: 10px;
        margin-bottom: 15px;
        line-height: 35px;
        letter-spacing: 1px;
      }
    }
  }

  .timeline-center {
    position: relative;

    &:before {
      background: #1f30b4;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      height: 88%;
      width: 2px;
    }

    .timeline-row {
      .tm-icon {
        width: 60px;
        height: 60px;
        text-align: center;
        position: absolute;
        top: 0;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 10px 0 rgb(138 138 138 / 16%);

        span {
          color: #1f30b4;
          font-size: 30px;
          line-height: 60px;
          padding: 1px;
        }
      }

      .tm-details {
        h4 {
          color: #000000;
          font-weight: 700;
          font-size: 20px;
        }

        p {
          font-size: 16px;
        }
      }

      &:nth-of-type(odd) {
        position: relative;
        text-align: right;
        width: 50%;

        .tm-icon {
          right: -30px;
        }

        .tm-details {
          margin-right: 40px;
          margin-bottom: 40px;
          color: #5f5f5f;
          font-size: 16px;
          line-height: 20px;
          background: #e6e1db;
          padding: 20px;
        }
      }

      &:nth-of-type(even) {
        position: relative;
        text-align: left;
        width: 50%;
        margin-left: 50%;

        .tm-icon {
          left: -30px;
        }

        .tm-details {
          margin-left: 40px;
          margin-bottom: 0px;
          color: #5f5f5f;
          font-size: 16px;
          line-height: 20px;
          background: #e6e1db;
          padding: 20px;
        }
      }
    }
  }
.usees {
  li {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0px;
  }
}

  .case-stable-coin {
    margin-top: 80px;

    .img-parent {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    ol {
      padding-left: 16px;
      margin-top: 20px;

      li {
        margin-bottom: 20px;
      }
    }
  }

  .why-section {
    margin-top: 80px;

    .img-parent {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .content {
      font-size: 18px;
    }
  }
}