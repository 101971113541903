.blockchain-section {
    .banner {
        width: 100%;
        height: 400px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    .description {
        color: #544f4f;
        font-size: 16px;
        text-align: justify;
        margin-bottom: 60px;
        // margin-top: 60px;
    }
    h2 {
        font-size: 38px;
        letter-spacing: 2px;
        margin-top: 80px;
        text-align: center;
        margin-bottom: 30px;
        font-weight: 800;
    }
    h4 {
        font-size: 25px;
        letter-spacing: 2px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 60px;
    }
    .platform-heading {
        margin-top: 80px;
    }
    .service-box, .platforms-box {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
        min-height: 150px;
        padding: 10px 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        .content {
            font-size: 14px;
            color: gray;
        }
        .img-parent {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        // h6 {
            
        // }
    }
    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;
        h4 {
            text-align: start;
        }
        ul {
            margin-top: 10px;
            padding-left: 10px;
        }
        .image-parent {
            // width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
