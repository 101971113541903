section {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    h2 {
        color: #5F65D5;
        font-size: 38px;
        letter-spacing: 2px;
        margin-top: 80px;
        text-align: center;
        margin-bottom: 60px;
        font-weight: 800;
    }
   
    h4, h5 {
        color: #1f30b4;
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 600;
    }
    .description {
        color: #544f4f;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 80px;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .work-box,
    .benifits-box {
        margin-bottom: 100px;
        ol {
            padding: 14px;
            margin-top: 30px;
            li {
                color: #544f4f;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 10px;
                line-height: 35px;
                letter-spacing: 1px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .clone-box {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
        min-height: 150px;
        padding: 10px 10px;
        font-size: 20px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        color: gray;
    }
    .bg-gray {
        background: #f2f2f2;
        padding: 30px 30px;
        .mainstream-box {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 399px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            text-align: center;
            flex-direction: column;
            h6 {
                font-size: 22px;
            }
            .content {
                font-size: 18px;
                font-weight: 400;
                color: #544f4f;
                text-align: justify;
                margin-top: 10px;
                margin-bottom: 15px;
                line-height: 35px;
                letter-spacing: 1px;
            }
            .img-parent {
                width: 115px;
                height: 115px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            .uniswap-img-parent {
                width: 100%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -56px;
                .uniswap {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: 100px 46px;
                }
            }
        }
    }
    .open-dex-desc,
    .answer {
        color: #544f4f;
        font-size: 18px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .why-us {
        margin-top: 100px;
    }
    .img-parent {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
