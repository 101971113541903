.footer-area {
    background: #252525;
    .footer-about {
        padding-right: 40px;
        .footer-logo {
            width: 70px;
            height: 70px;
            margin-bottom: 15px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                background: white;
                border-radius: 15px;
                
            }
        }
        .footer-desc {
            color: #e0dbdb;
            font-size: 15px;
            text-align: justify;
        }
        .footer-social-icon {
            li {
                margin-right: 10px;
                color: #fff;
                width: 40px;
                height: 40px;
                border-radius: 3px;
                text-align: center;
                border: 1px solid transparent;
                background: #2f2f2f;
                display: inline-block;
                a {
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .footer-list {
        .title {
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 25px;
        }
        ul {
            list-style: none;
            li  {
                margin-bottom: 8px;
                color: #e0dbdb;
                a{
                    display: inline-block;
                    font-size: 15px;
                    color: #e0dbdb;
                    text-decoration: unset;
                }
            }
        }
        .footer-contact-info {
            .footer-contact-list {
                li {
                    span {
                        display: inline-block;
                        color: #e0dbdb;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: .5px;
                    }
                }
            }
        }
    }
}
.footer-box-item-parent {
    .row {
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
    }
}
.copyright-area {
    background-color: #1f1f1f;
    padding-top: 35px;
    padding-bottom: 35px;
    .copyright-text, .terms {
        color: #e0dbdb;
        font-size: 15px;
        .author {
            color: #fff;
            font-size: 15px;
            font-weight: 600;
        }
    }
}
