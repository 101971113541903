@import "../../App.scss";
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mymove {
  0%,
  100% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(-10deg) scale(1);
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
  }
}

.main-banner {
  background-image: url("../../images/1.jpg");
  height: 87vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
  .banner-text {
    max-width: 550px;
    margin-top: 180px;
    color: white;
    h1 {
      font-size: 45px;
      font-weight: 800;
      letter-spacing: 1px;
      line-height: 1.21;
    }
    .creating-text {
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 1.21;
      span {
        margin-left: 10px;
      }
    }
  }
}
.bg-cream {
  background-color: #fef0e5;
  position: relative;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 50px;
  // height: 70vh;
  &:before {
    position: absolute;
    background-image: url(../../images/shape_banner.png);
    z-index: 1;
    right: 30px;
    background-repeat: no-repeat;
    background-position: top 0 left 0;
    animation: 2s linear infinite wiggle;
    content: "";
    display: block;
    background-size: 303px 242px;
    width: 303px;
    height: 242px;
    top: 50px;
    box-sizing: inherit;
  }
  .img-parent {
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    &:before {
      background-image: url(../../images/layer_circle.png);
      background-size: 60px 60px;
      height: 60px;
      top: 275px;
      z-index: 1;
      left: 45px;
      transform: translatey(0);
      animation: 3s ease-in-out infinite float;
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      display: block;
      width: 60px;
    }
  }
  .bg-head {
    h2 {
      background-size: 30% 100% !important;
      padding: 15px 30px;
      color: #000300;
      font-size: 35px;
      font-weight: 900;
      background-image: url(../../images/bg-head-blue.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0;
    }
  }
  .about-blockchain {
    p {
      width: 100%;
      position: relative;
      letter-spacing: 1px;
      z-index: 2;
      color: #3e3d3d;
      font-size: 18px;
      text-align: justify;
      font-weight: 400;
      line-height: 35px;
    }
    .card-light {
      border: none;
      border-radius: 5px;
      color: #434343;
      font-size: 14px;
      box-shadow: 0 0 16px 5px rgb(147 147 147 / 20%);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      font-weight: 600;
      background-clip: border-box;
    }
  }
}

.home-service-sec {
  background: #7534fc;
  .section-title {
    text-align: left;
    h2 {
      color: #fff;
    }
    h6 {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
  }
}

.technology-section {
  background: url(../../images/tech-bg.jpg);
  height: auto;
  .tech-img-parent {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    .tech-layer {
      left: 20px;
      top: 130px;
      max-width: 60px;
      transform: translatey(0);
      animation: 6s ease-in-out infinite float;
      position: absolute;
    }
    .tech-girl {
      position: relative;
      transform: scale(1);
      z-index: 2;
      top: 20%;
      left: 0;
      width: 100%;
    }
    .tech-img-circle {
      position: absolute;
      left: 1%;
      top: 34%;
      transform: scale(1);
      z-index: 1;
      animation: 5s infinite alternate mymove;
      width: 115%;
    }
  }
  .tech-parent {
    h2 {
      font-size: 25px;
      font-weight: 900;
      color: white;
      text-align: start;
    }
    h3 {
      font-size: 35px;
      font-weight: 700;
      line-height: 50px;
      color: white;
    }
    .head-abt {
      color: #fff6ee;
      font-size: 16px;
      font-weight: 400;
    }
    .tech-rows {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 30px;
      .tech {
        padding: 15px 13px;
        background-color: #0000005e;
        box-shadow: 0 0 16px 5px rgb(147 147 147 / 20%);
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-color: #525252;
        width: 180px;
        height: 217px;
        .title {
          color: #fcfcfc;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
        }
        .tech-ul {
          padding-left: 10px;
          li {
            color: #7e7e7e;
            list-style-type: none;
            font-weight: 700;
            margin-bottom: 15px;
            position: relative;
            padding-left: 17px;
            font-size: 14px;
            &:before {
              position: absolute;
              content: "";
              width: 6px;
              height: 6px;
              background-color: #fdcc0a;
              border-radius: 50%;
              top: 6px;
              left: 0;
              box-shadow: 0.3px 0.3px 6px 2px rgb(253 204 10 / 57%);
              animation: 1s infinite glowing;
            }
          }
        }
      }
    }
  }
}

.industry-section {
  background-color: #3476fa;
  padding: 80px 0px;
  // height: 70vh;
  h2 {
    text-align: start;
    font-size: 25px;
    font-weight: 900;
    color: white;
    margin-top: 0px;
  }
  h3 {
    text-align: start;
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
    color: white;
  }
  // .industry-circle {
  //   top: -40px;
  //   right: 40px;
  //   max-width: 170px;
  //   animation: 2s linear infinite wiggle;
  //   position: absolute;
  // }
  .industry-parent {
    .row {
      flex-wrap: wrap;
      column-gap: 25px;
      row-gap: 40px;
      justify-content: center;
      .industry {
        width: 160px;
        height: 105px;
        border-color: #866ef7;
        background-color: #e2dddd26;
        color: #f0e0f7 !important;
        border-radius: 0;
        position: relative;
        box-shadow: 0 0 2px 2px rgb(0 0 0 / 20%);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
          width: 38px;
          height: 38px;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 0px;
          font-size: 13px;
        }
      }
    }
  }
}
.mini-features-area {
  margin-top: -85px;
  .single-mini-feature {
    position: relative;
    z-index: 1;
    padding: 25px 15px;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0px 2px 25px 0px rgb(85 85 85 / 10%);
    box-shadow: 0px 2px 25px 0px rgb(85 85 85 / 10%);
    border-radius: 5px;
    top: 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    a {
      display: flex;
      text-decoration: unset;
    }
    .mini-feature-icon {
      i {
        color: $primary-color-blue;
        font-size: 35px;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        border-radius: 38% 62% 62% 38% / 41% 44% 56% 59%;
        display: block;
        background: #f7f7f7;
      }
    }
    .mini-feature-text {
      text-align: left;
      margin-left: 25px;
      color: gray;
      font-size: 14px;
      h3 {
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: 700;
        text-align: center;
        color: #072658;
        letter-spacing: 3px;
      }
    }
  }
}

.services-section {
  .section-title {
    text-align: left;
  }
  .services-category-box {
    a {
      color: $primary-color-red;
      text-decoration: none;
      font-size: 15px;
      padding: 10px 30px;
      border-radius: 3px;
      text-align: center;
      font-weight: 700;
      background-color: #f4f5ff;
      display: inline-block;
      min-width: 120px;
      margin-right: 30px;
      margin-bottom: 20px;
      transition: all 400ms ease-out;
      will-change: transform;
      &:hover {
        color: #fff;
        background-color: $primary-color-red;
      }
    }
  }
  .services-category-info {
    margin-top: 30px;
    margin-bottom: 50px;
    h6 {
      font-size: 18px;
      color: #5f5f5f;
    }
  }

  .single-services-box-2 {
    position: relative;
    z-index: 1;
    display: block;
    padding: 35px 30px;
    text-align: left;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 30px;
    border: 2px solid transparent;
    -webkit-box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
    -moz-box-shadow: 5px 7px 15px 2px rgba(82, 90, 101, 0.12);
    box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    will-change: transform;
    .services-icon-2 {
      display: block;
      color: $primary-color-red;
      margin-bottom: 20px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      svg {
        font-family: "icomoon";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        font-size: 55px;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 0;
    }
    .service-desc {
      font-size: 15px;
      margin-top: 15px;
      margin-bottom: 0;
    }
    .services-btn {
      margin-top: 16px;
      text-align: right;
      .services-btn-one {
        display: inline-block;
        font-size: 15px;
        color: $primary-color-red;
        font-weight: 700;
        -webkit-transition: all 400ms ease-out;
        transition: all 400ms ease-out;
        will-change: transform;
      }
    }
  }
}

.team-area {
  padding-bottom: 100px;
  .section-title {
    margin-bottom: 30px;
    h6 {
      font-size: 16px;
      color: $primary-color-red;
      font-weight: 700;
      margin-bottom: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    h2 {
      font-size: 38px;
      color: #0b0b31;
      font-weight: 800;
      margin: 0;
    }
  }
  .single-team-box {
    margin-top: 35px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    .team-image {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        height: auto;
      }
    }
    .team-info {
      padding: 20px 15px 15px;
      text-align: center;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }
      span {
        color: #5764ec;
        font-size: 14px;
        font-weight: 600;
        display: block;
      }
    }
  }
}

.testimonial-area {
  text-align: center;
  background-color: rgba(136, 116, 181, 0.11);
  .testimonial-content {
    flex-wrap: wrap;
  }
  .single-testimonial {
    position: relative;
    background-color: #fff;
    padding: 40px 35px;
    border-radius: 5px;
    width: 400px;
    height: 400px;
    margin: 15px;
    margin-bottom: 20px;
    transition: all 0.4s;
    border-bottom: 3px solid transparent;
    .rating-box {
      ul {
        li {
          display: inline-block;
          svg {
            color: #ffce39;
            font-size: 18px;
          }
        }
      }
    }
    .testimonial-content {
      margin-top: 15px;
      margin-bottom: 25px;
      p {
        color: #7d7d7d;
        font-size: 15px;
      }
    }
    .testimonial-bio {
      display: flex;
      align-items: center;
      justify-content: center;
      .avtar {
        img {
          border-radius: 50%;
          width: 80px;
          margin: 0 auto;
        }
      }
      .bio-info {
        text-align: left;
        margin-left: 15px;
        .name {
          font-size: 18px;
          font-weight: 600;
          margin-top: 2px;
        }
        span {
          font-size: 15px;
          color: #555;
        }
      }
    }
  }
}

.hire-area {
  .hire-wrapper {
    padding: 40px 0;
    background-color: rgba(136, 116, 181, 0.11);
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background-image: url("../../images/hire-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    // height: 880px;
    &:before {
      position: absolute;
      top: 0;
      content: "";
      left: 0px;
      padding: 0;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      z-index: -1;
      background: rgba(47, 55, 136, 0.5);
    }
    .hire-content {
      .form-parent {
        background: #2b272778;
        h4 {
          color: #fff;
        }
        form {
          label {
            color: white;
          }
          input,
          textarea {
            background: transparent;
          }
          input[type="submit"] {
            background: white;
            color: black;
          }
        }
      }
      h2 {
        color: #fff;
        font-size: 38px;
        font-weight: 800;
        margin-bottom: 15px;
      }
      h5 {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
      }
    }
  }
}

.blog-area {
  .single-blog {
    background: #ffffff;
    -webkit-box-shadow: 0 3px 15px -5px rgb(68 107 138 / 30%);
    box-shadow: 0 3px 15px -5px rgb(68 107 138 / 30%);
    border-radius: 3px;
    margin-top: 35px;
    overflow: hidden;
    a {
      text-decoration: none;
    }
    .post-img {
      position: relative;
      overflow: hidden;
      height: 220px;
    }
    .blog-content {
      padding: 25px 25px;
      text-align: left;
      .blog-date {
        .blog-list {
          li {
            display: inline-block;
            h6 {
              font-size: 13px;
              color: #777;
              display: inline-block;
            }
            span {
              padding: 0 5px;
              font-size: 12px;
              color: #333;
            }
            a {
              font-size: 13px;
              color: #5764ec;
              font-weight: 600;
              display: inline-block;
            }
          }
        }
      }
      .blog-body-title {
        h3 {
          a {
            font-size: 18px;
            font-weight: 700;
            color: #333;
            line-height: 1.5;
            margin-bottom: 10px;
            display: block;
          }
        }
      }
      .blog-body-text {
        p {
          margin-bottom: 0px;
          font-size: 15px;
          line-height: 1.8;
          color: #747576;
          margin-bottom: 15px;
          font-weight: 400;
        }
      }
      .read-more-btn {
        a {
          color: #5f5f5f;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.7px;
          display: block;
        }
      }
    }
  }
}

.owl-stage-outer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .technology-section {
    .tech-img-parent {
      display: none;
    }
    .tech-parent {
      width: 100%;
      .tech-rows {
        column-gap: 10px;
      }
    }
  }
  .industry-section {
    .industry-content {
      flex-direction: column;
      .industry-head {
        width: 100%;
        text-align: center;
        h2 {
          margin-bottom: 20px;
        }
        h2,
        h3 {
          text-align: center;
        }
      }
      .industry-parent {
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .testimonial-area {
    .single-testimonial {
      width: 100%;
    }
  }
}
