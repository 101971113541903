.section-ido {
  .banner {
    width: 100%;
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  h2 {
    color: #015c7e;
    margin-top: 80px;
    font-size: 38px;
    text-align: center;
    margin-bottom: 60px;
    font-weight: 800;
  }
  h4 {
    color: #015c7e;
    font-size: 27px;
    letter-spacing: 1px;
    font-weight: 800;
  }
  .description {
    color: #544f4f;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 80px;
    line-height: 35px;
    letter-spacing: 1px;
  }
  .bg-gray {
    background: #ced7eb;
    padding: 30px 30px;
    .benefit-box {
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
      min-height: 150px;
      padding: 20px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      h6 {
        color: black;
        font-size: 20px;
        letter-spacing: 1px;
        font-weight: 500;
      }
      .content {
        font-size: 18px;
        font-weight: 400;
        color: darkslategray;
        text-align: justify;
        margin-top: 10px;
        line-height: 35px;
        letter-spacing: 1px;
      }
    }
    .launchpad-types {
      background: #fff;
      padding: 50px 10px;
      h5 {
        font-size: 25px;
        font-weight: 700;
      }
      .launchpad-box {
        ul {
          list-style: none;
          li {
            color: #544f4f;
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  .approach {
    h4 {
      margin-top: 80px;
    }
    .approach-box {
      ul {
        li {
          span {
            font-weight: 700;
            color: black;
            font-size: 20px;
            line-height: 35px;
            letter-spacing: 1px;
          }
          color: gray;
          font-size: 18px;
          margin-top: 20px;
          line-height: 35px;
          letter-spacing: 1px;
        }
      }
    }
    .ido-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 14px;
      }
    }
  }
  .why-section {
    margin-top: 70px;
    .why-us {
      h5 {
        color: #015c7e;
        font-size: 27px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      ul {
        li {
          color: #544f4f;
          font-size: 18px;
          font-weight: 400;
          margin-top: 10px;
          line-height: 35px;
          letter-spacing: 1px;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .form-parent {
    input[type="submit"] {
      background: #015c7e;
    }
  }
}
