.nft-staking-section {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    h2 {
        color: #0d0a7d;
        font-size: 38px;
        letter-spacing: 2px;
        margin-top: 80px;
        text-align: center;
        margin-bottom: 60px;
        font-weight: 800;
    }
    h4 {
        color: #0d0a7d;
        font-size: 25px;
        letter-spacing: 2px;
        font-weight: 800;
        text-align: center;
    }
    .description {
        color: #544f4f;
        font-size: 16px;
        text-align: justify;
        margin-bottom: 60px;
    }
    .website-parent {
        margin-top: 80px;
        .title {
            margin-bottom: 50px;
        }
        .websites {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 150px;
            padding: 20px 22px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            flex-direction: column;
            .img-parent {
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
                img {
                    // border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .name {
                color: black;
                font-size: 18px;
            }
            .about {
                color: #544f4f;
                font-size: 14px;
            }
        }
    }
    .bg-gray {
        background: #f2f2f2;
        padding: 30px 30px;
        margin-top: 80px;
        .timeline-center {
            position: relative;
            &:before {
                background: #0d0a7d;
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                height: 88%;
                width: 2px;
            }
            .timeline-row {
                .tm-icon {
                    width: 60px;
                    height: 60px;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    background-color: #fff;
                    border-radius: 50%;
                    box-shadow: 0 0 10px 0 rgb(138 138 138 / 16%);
                    span {
                        color: #0d0a7d;
                        font-size: 30px;
                        line-height: 60px;
                        padding: 1px;
                    }
                }
                .tm-details {
                    h4 {
                        color: #000000;
                        font-weight: 700;
                    }
                }

                &:nth-of-type(odd) {
                    position: relative;
                    text-align: right;
                    width: 50%;
                    .tm-icon {
                        right: -30px;
                    }
                    .tm-details {
                        margin-right: 40px;
                        margin-bottom: 40px;
                        color: #5f5f5f;
                        font-size: 12px;
                        line-height: 20px;
                        background: #fff;
                        padding: 20px;
                        h4 {
                            text-align: end;
                        }
                    }
                }
                &:nth-of-type(even) {
                    position: relative;
                    text-align: left;
                    width: 50%;
                    margin-left: 50%;
                    .tm-icon {
                        left: -30px;
                    }
                    .tm-details {
                        margin-left: 40px;
                        margin-bottom: 0px;
                        color: #5f5f5f;
                        font-size: 12px;
                        line-height: 20px;
                        background: #fff;
                        padding: 20px;
                        h4 {
                            text-align: start;
                        }
                    }
                }
            }
        }
    }
    .digital-collection-parent {
        margin-top: 80px;
        .collection-title {
            margin-bottom: 50px;
        }
        .digital-collection {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 150px;
            padding: 20px 22px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            flex-direction: column;
            .img-parent {
                width: 100px;
                height: 100px;
                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .collection-name {
                color: black;
                font-size: 18px;
            }
            .about {
                color: #544f4f;
                font-size: 14px;
            }
        }
    }
    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;
        h4 {
            text-align: start;
        }
        ul {
            margin-top: 10px;
            padding-left: 10px;
            li {
                margin-bottom: 10px;
            }
        }
        .image-parent {
            // width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
