.terms-condition-section {
  .terms-condition-page {
      .title {
          display: flex;
          height: 280px;
          align-items: center;
          justify-content: center;
          h2 {
              font-size: 60px;
              font-weight: 700;
          }
      }
  }
  .terms-secs {
      .website-link {
          font-size: 18px;
          letter-spacing: 1px;
      }
      .description {
          color: #555;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 1px;
          margin-bottom: 60px;
      }
  }
}
