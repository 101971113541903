.section-dao {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    h2 {
        color: #045c77;
        font-size: 38px;
        margin-top: 60px;
        margin-bottom: 27px;
        letter-spacing: 5px;
        font-weight: 800;
        text-align: center;
    }
    .description {
        color: #544f4f;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 60px;
        line-height: 35px;
        letter-spacing: 1px;
    }
    h4 {
        color: #045c77;
        font-size: 22px;
        letter-spacing: 1px;
        margin-top: 90px;
    }
    .bg-gray {
        background: #f2f2f2;
        padding: 30px 30px;
        .feature-box {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 150px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            img {
                width: 100px;
                height: 100px;
            }
            h6 {
                color: #045c77;
                margin-top: 15px;
                font-size: 20px;
                font-weight: 700;
            }
            .content {
                font-size: 18px;
                font-weight: 400;
                color: #544f4f;
                text-align: center;
                margin-top: 10px;
                line-height: 35px;
                letter-spacing: 1px;
            }
        }
        .services {
            margin-top: 50px;
            h4 {
                margin-top: 80px;
            }
            ul {
                padding-left: 12px;
                li {
                    span {
                        color: black;
                        font-size: 18px;
                        font-weight: 800;
                    }
                    margin-top: 20px;
                    color: #544f4f;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 35px;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .form-parent {
        h4 {
            font-size: 36px;
            line-height: 40px;
            font-weight: 300;
            color: #045c77;
            margin-top: unset;
        }
        input[type="submit"] {
            background: #045c77;
            color: white;
            font-size: 20px;
        }
    }
    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;
        h4 {
            text-align: start;
        }
        .content {
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 1px;
        }
        .image-parent {
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
