@import "../../../App.scss";

.about-section {
    .about-us-page {
        .title {
            display: flex;
            height: 280px;
            align-items: center;
            justify-content: center;
            h2 {
                font-size: 60px;
                font-weight: 700;
            }
        }
    }

    .description {
        color: #555;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 1px;
        margin-bottom: 60px;
    }

    .best {
        margin-top: 100px;
    }
    .best-description {
        font-size: 18px;
        color: #555;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .about-desc {
        .row {
            margin-top: 40px;
            justify-content: space-around;
            margin-bottom: 100px;
            .key-feature {
                background-color: #fff;
                border-radius: 30px;
                box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
                min-height: 215px;
                padding: 20px 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                flex-direction: column;
                .title {
                    font-size: 20px;
                    font-weight: 700;
                }
                .content {
                    font-size: 18px;
                    font-weight: 400;
                    color: gray;
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }
    }
    .services {
        // background-color: red;
        height: 400px;
        .icon-parent {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 45px;
            .gyan-infobox-icon-holder {
                background-color: rgba(255, 255, 255, 0.13);
                height: 119px;
                width: 119px;
                border-radius: 50%;
                .gyan-infobox-image {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        width: 100%;
                        height: 50%;
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
        h3 {
            color: white;
            font-weight: 600;
            text-align: center;
            margin-top: 40px;
        }
        .content {
            color: #fff;
            text-align: center;
        }
    }
    .bg-green {
        background-color: #00a468;
    }
    .bg-blue {
        background-color: #1190ef;
    }
    .bg-yellow {
        background-color: #eaa510;
    }
}
