.app-development-section {
    .banner {
        width: 100%;
        height: 500px;

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    h2 {
        color: #0d0a7d;
        font-size: 38px;
        letter-spacing: 2px;
        margin-top: 80px;
        text-align: center;
        margin-bottom: 60px;
        font-weight: 800;
    }

    h4 {
        color: #0d0a7d;
        font-size: 25px;
        letter-spacing: 2px;
        font-weight: 800;
        text-align: center;
    }

    .description {
        color: #544f4f;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 1px;
        text-align: justify;
        margin-bottom: 60px;
    }

    .personalized-nft-parent {
        margin-top: 80px;

        .title {
            margin-bottom: 50px;
        }
        ul {
            li {
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 0.7px;
            }
        }
    }

    .bg-gray {
        background: #f2f2f2;
        padding: 30px 30px;
        margin-top: 80px;

        .timeline-center {
            position: relative;

            &:before {
                background: #0d0a7d;
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                height: 88%;
                width: 2px;
            }

            .timeline-row {
                .tm-icon {
                    width: 60px;
                    height: 60px;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    background-color: #fff;
                    border-radius: 50%;
                    box-shadow: 0 0 10px 0 rgb(138 138 138 / 16%);

                    span {
                        color: #0d0a7d;
                        font-size: 30px;
                        line-height: 60px;
                        padding: 1px;
                    }
                }

                .tm-details {
                    h4 {
                        color: #000000;
                        font-weight: 700;
                    }
                }

                &:nth-of-type(odd) {
                    position: relative;
                    text-align: right;
                    width: 50%;

                    .tm-icon {
                        right: -30px;
                    }

                    .tm-details {
                        margin-right: 40px;
                        margin-bottom: 40px;
                        color: #5f5f5f;
                        font-size: 16px;
                        line-height: 20px;
                        background: #fff;
                        padding: 20px;

                        h4 {
                            text-align: end;
                        }
                    }
                }

                &:nth-of-type(even) {
                    position: relative;
                    text-align: left;
                    width: 50%;
                    margin-left: 50%;

                    .tm-icon {
                        left: -30px;
                    }

                    .tm-details {
                        margin-left: 40px;
                        margin-bottom: 0px;
                        color: #5f5f5f;
                        font-size: 16px;
                        line-height: 20px;
                        background: #fff;
                        padding: 20px;

                        h4 {
                            text-align: start;
                        }
                    }
                }
            }
        }
    }

    .industry-parent {
        margin-top: 80px;

        h4 {
            margin-bottom: 30px;
        }

        .testimonials-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            row-gap: 30px;

            .testimonials-item {
                display: block;
                position: relative;
                margin-bottom: 30px;

                .user {
                    width: 70px;
                    height: 70px;
                    position: relative;
                    position: absolute;
                    top: -10px;
                    left: 25px;
                    z-index: 3;
                    -webkit-transition: all 0.3s ease 0s;
                    -moz-transition: all 0.3s ease 0s;
                    -o-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    overflow: hidden;
                    background: #0d0a7d;
                    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
                    line-height: 80px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 55px;
                        height: 55px;
                        filter: brightness(9);
                    }
                }

                .testimonials-content {
                    background: #ffffff;
                    overflow: hidden;
                    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    position: relative;
                    height: 100%;
                    min-height: auto;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        background: #f2f2f2 !important;
                        opacity: 0;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                    }

                    .user-name {
                        display: block;
                        font-weight: 600;
                        font-size: 21px;
                        color: #0d0a7d;
                        margin-top: 20px;
                        padding-left: 115px;
                        letter-spacing: 0.69px;
                        margin-bottom: 5px;
                        position: relative;
                        z-index: 3;
                        text-transform: capitalize;
                    }

                    .txt {
                        margin-top: -20px;

                        p {
                            padding-left: 25px;
                            padding-right: 25px;
                            margin-bottom: 25px;
                            margin-top: 60px !important;
                            font-weight: 400;
                            min-height: auto;
                            font-size: 16px;
                            color: #000000;
                            letter-spacing: 0.6px;
                            line-height: 26px;
                            position: relative;
                            z-index: 3;
                        }
                    }
                }
            }
        }
    }

    .technologies-parent {
        .technologies {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
            min-height: 95px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;

            .img-parent {
                width: 100px;
                height: 60px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    border-radius: 50%;
                }
            }
        }
    }

    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;

        h4 {
            text-align: start;
        }

        .content {
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 0.7px;
        }

        ul {
            margin-top: 10px;
            padding-left: 10px;

            li {
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 0.7px;
            }
        }

        .image-parent {
            // width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}