@import "../../../App.scss";

.services-section {
  // background: #7534FC;
  .section-title {
    text-align: left;
    h6 {
      color: gray;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
  }
  .services-category-box {
    flex-wrap: wrap;
    row-gap: 15px;
    .technology-box {
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
      width: 300px;
      min-height: 484px;
      border: 1px solid #e5e5e5;
      padding: 0px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-align: center;
      flex-direction: column;
      .img-parent {
        width: 100%;
        height: 200px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
        }
      }
      h5 {
        margin-top: 20px;
        // text-align: center;
      }
      .content {
        font-size: 16px;
        color: gray;
        text-align: justify;
      }
    }
    a {
      color: $primary-color-red;
      text-decoration: none;
      font-size: 15px;
      padding: 10px 30px;
      border-radius: 3px;
      text-align: center;
      font-weight: 700;
      background-color: #f4f5ff;
      display: inline-block;
      min-width: 120px;
      margin-right: 30px;
      margin-top: 20px;
      transition: all 400ms ease-out;
      will-change: transform;
      &:hover {
        color: #fff;
        background-color: $primary-color-red;
      }
    }
  }
  .services-category-info {
    margin-top: 30px;
    margin-bottom: 50px;
    h6 {
      font-size: 18px;
      color: #5f5f5f;
    }
  }

  .single-services-box-2 {
    position: relative;
    z-index: 1;
    display: block;
    padding: 35px 30px;
    text-align: left;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 30px;
    border: 2px solid transparent;
    -webkit-box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
    -moz-box-shadow: 5px 7px 15px 2px rgba(82, 90, 101, 0.12);
    box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    will-change: transform;
    .services-icon-2 {
      display: block;
      color: $primary-color-red;
      margin-bottom: 20px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      svg {
        font-family: "icomoon";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        font-size: 55px;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 0;
    }
    .service-desc {
      font-size: 15px;
      margin-top: 15px;
      margin-bottom: 0;
    }
    .services-btn {
      margin-top: 16px;
      text-align: right;
      .services-btn-one {
        display: inline-block;
        font-size: 15px;
        color: $primary-color-red;
        font-weight: 700;
        -webkit-transition: all 400ms ease-out;
        transition: all 400ms ease-out;
        will-change: transform;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services-section {
    .services-category-box {
      .technology-box {
        width: 400px;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .services-section {
    .services-category-box {
      .technology-box {
        width: 90%;
      }
    }
  }
}
