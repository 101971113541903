.crypto-currency-section,
.initialcoin-section {
  .banner {
    width: 100%;
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  h2 {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 800;
  }
  .description {
    color: gray;
    font-size: 18px;
    text-align: justify;
    margin-bottom: 80px;
    line-height: 35px;
    letter-spacing: 1px;
  }
  .services {
    li {
      span {
        color: black;
        font-size: 20px;
        font-weight: 600;
        margin-right: 6px;
        line-height: 35px;
        letter-spacing: 1px;
      }
      color: gray;
      font-size: 18px;
      text-align: justify;
      margin-bottom: 15px;
      line-height: 35px;
      letter-spacing: 1px;
    }
  }
  .platforms {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
    min-height: 150px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    .img-parent {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50%;
      }
    }
  }
  .bg-gray {
    background: #f2f2f2;
    padding: 30px 30px;
    margin-top: 80px;
    p {
      color: gray;
      font-size: 18px;
      margin-top: 40px;
      line-height: 30px;
      letter-spacing: 1px;
    }
    .timeline-center {
      position: relative;
      &:before {
        background: red;
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        height: 88%;
        width: 2px;
      }
      .timeline-row {
        .tm-icon {
          width: 60px;
          height: 60px;
          text-align: center;
          position: absolute;
          top: 0;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 10px 0 rgb(138 138 138 / 16%);
          span {
            color: red;
            font-size: 30px;
            line-height: 60px;
            padding: 1px;
          }
        }
        .tm-details {
          h4 {
            color: #000000;
            font-weight: 700;
          }
        }

        &:nth-of-type(odd) {
          position: relative;
          text-align: right;
          width: 50%;
          .tm-icon {
            right: -30px;
          }
          .tm-details {
            margin-right: 40px;
            margin-bottom: 40px;
            color: #5f5f5f;
            font-size: 16px;
            line-height: 20px;
            background: #fff;
            padding: 20px;
          }
        }
        &:nth-of-type(even) {
          position: relative;
          text-align: left;
          width: 50%;
          margin-left: 50%;
          .tm-icon {
            left: -30px;
          }
          .tm-details {
            margin-left: 40px;
            margin-bottom: 0px;
            color: #5f5f5f;
            font-size: 16px;
            line-height: 20px;
            background: #fff;
            padding: 20px;
          }
        }
      }
    }
  }
}
