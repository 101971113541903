.contact-section {
    .banner {
        width: 100%;
        height: 400px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    .form-parent {
        background: #ffffff;
        border: 1px solid rgba(241, 243, 249, 0.6);
        box-shadow: 0px 4px 15px rgba(164, 164, 164, 0.19);
        border-radius: 10px;
        padding: 25px;
        h4 {
            font-size: 36px;
            line-height: 40px;
            font-weight: 300;
            color: #0D0A7D;
        }
        form {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            input, textarea {
                border: 1px solid #d3cccc;
                padding: 10px;
                border-radius: 4px;
            }
            input[type=submit] {
                background: #0D0A7D;
                color: white;
                font-size: 20px;
            }
        }
    }
}
