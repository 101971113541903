.first-blog-section {
  background: #F7F9FB;
  .blog-detail {
    box-shadow: 0 3px 15px -5px rgb(68 107 138 / 30%);
    background: #ffffff;
    padding: 100px;
  }
  .img-parent {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    img {
      width: 100%;
      border-radius: 15px;
    }
  }
  .description {
    color: #555;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 2px;
    margin-bottom: 35px;
    text-align: justify;
    span {
      font-size: 22px;
      font-weight: 800;
    }
  }
}