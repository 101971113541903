.nft-marketplace-physical-section {
  .banner {
    width: 100%;
    height: 500px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    h2 {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      color: white;
      font-size: 50px;
      font-weight: 800;
    }
  }
  .description {
    color: #555;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  .assets-parent {
    .img-parent {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    h2 {
      color: #042673;
      font-size: 35px;
      letter-spacing: 4px;
    }
    .nft-box {
      h4 {
        color: #042673;
        font-size: 22px;
        letter-spacing: 2px;
      }
      .content {
        color: #544f4f;
        font-size: 18px;

        line-height: 35px;
        letter-spacing: 1px;
      }
    }
  }
  .bg-gray {
    background: #f2f2f2;
    margin-top: 80px;
    h4 {
      color: #042673;
      font-size: 22px;
      letter-spacing: 2px;
      text-align: center;
    }
    .factor-parent {
      row-gap: 20px;
      column-gap: 0px;
    }
    .feature-nft {
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
      height: 300px;
      padding: 10px 22px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      text-align: center;
      flex-direction: column;
      .name {
        font-size: 20px;
        color: black;
        font-weight: 500;
        margin-bottom: 10px;
        height: 52px;
        text-align: center;
        display: flex;
        align-items: center;
      }
      .about {
        color: gray;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
  .why-us {
    margin-top: 100px;
    margin-bottom: 100px;
    h4 {
      text-align: start;
    }
    ul {
      margin-top: 10px;
      padding-left: 10px;
      li {
        font-size: 16px;
        letter-spacing: 0.5px;
      }
    }
    .image-parent {
      // width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
