@import '../../../App.scss';

.services-page {
    .title {
        display: flex;
        height: 280px;
        align-items: center;
        justify-content: center;
        h2 {
            font-size: 60px;
            font-weight: 700;
        }
    }
}