.initialcoin-section {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    h2 {
        margin-top: 100px;
        text-align: center;
        margin-bottom: 50px;
        font-weight: 800;
    }
    .description {
        color: gray;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 80px;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .ico-service-box {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
        min-height: 150px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        flex-direction: column;
    }
}
