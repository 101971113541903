.smart-contract-section {
  .banner {
    width: 100%;
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-bottom: 1px solid #e6e3e3;
    }
  }
  h2 {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 800;
    color: #000360;
  }
  .description {
    color: gray;
    font-size: 18px;
    text-align: justify;
    margin-bottom: 80px;
    line-height: 35px;
    letter-spacing: 1px;
  }
  h4 {
    font-size: 28px;
    font-weight: 600;
    color: #000360;
  }
  .benifits-box {
    .benifits {
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 4px 50px 0 rgb(0 0 0 / 10%);
      min-height: 237px;
      padding: 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      h6 {
        color: #000360;
        font-size: 20px;
      }
      .content {
        font-size: 18px;
        font-weight: 400;
        color: gray;
        text-align: center;
        margin-top: 10px;
        line-height: 35px;
        letter-spacing: 1px;
      }
    }
  }
  .bg-gray {
    background: #f2f2f2;
    padding: 30px 30px;
    margin-top: 80px;
    p {
      color: gray;
      font-size: 18px;
      margin-top: 40px;
      line-height: 35px;
      letter-spacing: 1px
    }
    .timeline-center {
      position: relative;
      &:before {
        background: red;
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        height: 88%;
        width: 2px;
      }
      .timeline-row {
        .tm-icon {
          width: 60px;
          height: 60px;
          text-align: center;
          position: absolute;
          top: 0;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 10px 0 rgb(138 138 138 / 16%);
          span {
            color: red;
            font-size: 30px;
            line-height: 60px;
            padding: 1px;
          }
        }
        .tm-details {
          h4 {
            color: #000360;
            font-weight: 700;
          }
        }

        &:nth-of-type(odd) {
          position: relative;
          text-align: right;
          width: 50%;
          .tm-icon {
            right: -30px;
          }
          .tm-details {
            margin-right: 40px;
            margin-bottom: 40px;
            color: #5f5f5f;
            font-size: 16px;
            line-height: 20px;
            background: #fff;
            padding: 20px;
            line-height: 25px;
            letter-spacing: 0px
          }
        }
        &:nth-of-type(even) {
          position: relative;
          text-align: left;
          width: 50%;
          margin-left: 50%;
          .tm-icon {
            left: -30px;
          }
          .tm-details {
            margin-left: 40px;
            margin-bottom: 0px;
            color: #5f5f5f;
            font-size: 16px;
            line-height: 20px;
            background: #fff;
            padding: 20px;
          }
        }
      }
    }
  }
  .industry-box {
    ul {
      li {
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        .name {
          font-size: 18px;
          font-weight: 400;
          line-height: 35px;
          letter-spacing: 1px
        }
      }
    }
  }
  .need-box {
    .need-title {
      margin-top: 100px;
    }
    ul {
      li {
        color: gray;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 1px
      }
    }
  }
  .why-section {
    .why-title {
      margin-top: 100px;
    }
    .content {
      color: gray;
      font-size: 18px;
      line-height: 35px;
      letter-spacing: 1px
    }
    .img-parent {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
