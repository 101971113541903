.defi {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    h2 {
        color: #002978;
        font-size: 40px;
        margin-top: 60px;
        margin-bottom: 27px;
        letter-spacing: 6px;
    }
    h5 {
        color: #002978;
        font-size: 22px;
        letter-spacing: 1px;
    }
    ol {
        padding-left: 17px;
        li {
            color: #544f4f;
            font-size: 18px;
            text-align: justify;
            margin-bottom: 10px;
            line-height: 35px;
            letter-spacing: 1px;
        }
    }
    .description {
        color: #544f4f;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 60px;
        line-height: 35px;
        letter-spacing: 1px;
    }
    h3 {
        color: #002978;
        font-size: 22px;
        letter-spacing: 1px;
        margin-top: 90px;
    }
    .image-parent {
        .lending {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .service-parent {
        margin-bottom: 60px;
        .right {
            text-align: end;
            h4 {
                text-align: start;
            }
        }
        .services {
            h4 {
                font-size: 20px;
                color: #002978;
                letter-spacing: 1px;
            }
            .content {
                color: #544f4f;
                font-size: 18px;
                text-align: justify;
                line-height: 35px;
                letter-spacing: 1px;
                a {
                    text-decoration: unset;
                }
            }
        }
    }

    .service-box {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 4px 50px 0 #0000001a;
        min-height: 150px;
        padding: 0px 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        svg {
            width: 50px;
            height: 50px;
            margin-bottom: 8px;
        }
    }
    .why-us {
        margin-top: 100px;
        margin-bottom: 100px;
        h4 {
            text-align: start;
        }
        .content {
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 1px;
        }
        .image-parent {
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
