@import "../../App.scss";

.staking {
    .banner {
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    h2 {
        color: #04376e;
        font-size: 38px;
        letter-spacing: 2px;
        font-weight: 800;
        margin-top: 80px;
        margin-bottom: 60px;
    }
    h4 {
        color: #04376e;
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 800;
    }
    .question {
        color: #04376e;
        font-size: 25px;
        font-weight: 600;
    }
    .image-parent {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 6px;
        }
    }
    .content {
        width: 100%;
        height: auto;
        font-weight: 400;
        color: #544f4f;
        font-size: 18px;
        text-align: justify;
        line-height: 35px;
        letter-spacing: 1px;
    }
    .benifits-box,
    .platforms {
        .images {
            width: 100%;
            height: 300px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
        .content,
        .title {
            width: 100%;
            padding: 15px;
            text-align: center;
            background: rgb(67, 130, 224);
            color: white;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    .features {
        margin-top: 100px;
        margin-bottom: 80px;
        .feature-box {
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 4px 50px 0 #0000001a;
            min-height: 150px;
            padding: 0px 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            img,
            svg {
                width: 90px;
                height: 80px;
                object-fit: contain;
            }
        }
    }
    .choose-us {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .form-parent {
        input[type="submit"] {
            background: #04376e;
        }
    }
}
