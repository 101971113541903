* {
  margin: 0;
  padding: 0;
}

$primary-color-blue: #40aee3;
$primary-color-red: #7c54a3;
$text-gray: #919289;

// background: hsla(235, 80%, 63%, 1);

// background: linear-gradient(315deg, hsla(235, 80%, 63%, 1) 0%, hsla(33, 100%, 69%, 1) 100%);

// background: -moz-linear-gradient(315deg, hsla(235, 80%, 63%, 1) 0%, hsla(33, 100%, 69%, 1) 100%);

// background: -webkit-linear-gradient(315deg, hsla(235, 80%, 63%, 1) 0%, hsla(33, 100%, 69%, 1) 100%);

// filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#5764EC", endColorstr="#FFB85F", GradientType=1 );

.common-page {
  background-image: linear-gradient(315deg, $primary-color-blue, $primary-color-red);
  min-height: 280px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.row-gap-25 {
  row-gap: 25px;
}

.column-gap-25 {
  column-gap: 25px;
}

.theme-btn {
  background-color: $primary-color-blue;
  background-position: right center;
  text-align: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: 0.3s;
  border: unset;
  border-radius: 20px;
  &:hover {
    background-color: $primary-color-blue;
    border: unset;
    padding: 10px 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
}


.mr-20 {
  margin-right: 20px;
}

.p-100 {
  padding: 100px 0px;
}


.pb-100 {
  padding-bottom: 100px;
}
.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.form-parent {
  background: #ffffff;
  border: 1px solid rgba(241, 243, 249, 0.6);
  box-shadow: 0px 4px 15px rgba(164, 164, 164, 0.19);
  border-radius: 10px;
  padding: 25px;
  h4 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 300;
    color: #0d0a7d;
  }
  form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    input,
    textarea {
      border: 1px solid #d3cccc;
      padding: 10px;
      border-radius: 4px;
    }
    input[type="submit"] {
      background: #0d0a7d;
      color: white;
      font-size: 20px;
    }
  }
}

.section-title {
  text-align: center;
  margin-bottom: 65px;
  h2 {
    font-size: 38px;
    color: #0b0b31;
    font-weight: 800;
    margin: 0;
  }
  h6 {
    font-size: 16px;
    color: $primary-color-red;
    font-weight: 700;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
}

.section-margin {
  margin-top: 100px;
  margin-bottom: 100px;
}
